import { template as template_3c48a16bce6f4cf8a2609337f5790761 } from "@ember/template-compiler";
const FKLabel = template_3c48a16bce6f4cf8a2609337f5790761(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
