import { template as template_47b9138b897c45b897f35e22e62859f0 } from "@ember/template-compiler";
const FKText = template_47b9138b897c45b897f35e22e62859f0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
