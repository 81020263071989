import { template as template_d30e893b50014885a03308c79713a8d7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d30e893b50014885a03308c79713a8d7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
