import { template as template_dd9eb43238ea4347a8969c1e4d58482c } from "@ember/template-compiler";
const SidebarSectionMessage = template_dd9eb43238ea4347a8969c1e4d58482c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
